import {useCookies} from 'react-cookie';

import {ConsumerCartAddItemInput, EntityNotFound, ValidationError} from '@/graphql/types';

export const PROMO_COOKIE = 'afp_code';

type UserError = EntityNotFound | ValidationError;

type UsePromoCodeResult = {
  promoCookieMatchesBrand: boolean;
  getCartCreateInput: (
    experience: string,
    input: ConsumerCartAddItemInput[],
    options?: {excludePromoCode: boolean; reorderId?: string},
  ) => any;
  removePromoCookie: () => void;
  isPromoCodeError: (errors: UserError[]) => boolean;
  handlePromoCodeError: (errors: UserError[]) => void;
};

export const usePromoCode = (brandId?: string): UsePromoCodeResult => {
  const [cookies, , removeCookie] = useCookies([PROMO_COOKIE]);
  const promoCookieMatchesBrand = cookies[PROMO_COOKIE]?.brand_id?.toString() === brandId;

  const getCartCreateInput = (
    experience: string,
    input: ConsumerCartAddItemInput[],
    options?: {excludePromoCode?: boolean; reorderId?: string},
  ) => {
    const {reorderId} = options || {};
    const baseInput = {
      experience,
      items: input,
    };
    if (
      promoCookieMatchesBrand &&
      cookies[PROMO_COOKIE]?.force_promo_param &&
      !options?.excludePromoCode
    ) {
      return {
        ...baseInput,
        promoCode: cookies[PROMO_COOKIE].force_promo_param,
        ...(reorderId && {reorderId}),
      };
    }
    return {
      ...baseInput,
      ...(reorderId && {reorderId}),
    };
  };

  const isPromoCodeError = (errors: UserError[]) => {
    const promoCodeError = errors.find(
      error => error.message === 'unknown error' && error.path.includes('promo_code'),
    );
    return !!promoCodeError;
  };

  const handlePromoCodeError = (errors: UserError[]) => {
    if (isPromoCodeError(errors)) {
      removePromoCookie();
    }
  };

  const removePromoCookie = () => {
    if (promoCookieMatchesBrand) {
      removeCookie(PROMO_COOKIE, {path: '/'});
    }
  };

  return {
    promoCookieMatchesBrand,
    getCartCreateInput,
    removePromoCookie,
    isPromoCodeError,
    handlePromoCodeError,
  };
};
