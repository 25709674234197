import {useContext} from 'react';

import DerivedDataContext from '../DerivedMenuDataProvider/context';
import {type DerivedContext} from '../DerivedMenuDataProvider/types';

const useDerivedMenuData = (): DerivedContext => {
  const contextValue = useContext(DerivedDataContext);
  return contextValue;
};

export default useDerivedMenuData;
