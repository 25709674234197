import useTracking from '@/hooks/useTracking';
import useDerivedMenuData from './useDerivedMenuData';
import useMenuPageData from './useMenuPageData';

type GroupOrderEvent =
  | 'group-order-invite-guests-modal-done-clicked'
  | 'menu-group-order-start-clicked'
  | 'menu-group-order-start-button-clicked'
  | 'menu-group-order-edit-clicked'
  | 'group-order-modal-cancel-clicked'
  | 'group-order-modal-save-clicked'
  | 'group-order-modal-create-order-clicked'
  | 'group-order-modal-viewed'
  | 'see-other-peoples-orders-clicked'
  | 'menu-group-order-item-over-budget-message-viewed';

const useGroupOrderTracking = () => {
  const {track} = useTracking();
  const {isGroupOrderEater, isGroupOrderOwner} = useDerivedMenuData();
  const {cartId, fulfillmentDetailId} = useMenuPageData();

  /**
   * General tracking event for group order features including url, page and default misc_json.
   * @param eventName Event name from dictionary
   * @param miscJson Any additional data in misc_json field
   */
  const trackGroupOrderEvent = (eventName: GroupOrderEvent, miscJson?: Record<string, unknown>) => {
    track(eventName, {
      url: window.location.href,
      page: window.location.pathname,
      misc_json: JSON.stringify({
        fulfillment_detail_id: fulfillmentDetailId,
        consumer_cart_id: cartId,
        is_group_order_owner: isGroupOrderOwner,
        is_group_order_eater: isGroupOrderEater,
        ...miscJson,
      }),
    });
  };

  return {
    trackGroupOrderEvent,
  };
};

export default useGroupOrderTracking;
