import {forwardRef, useEffect, useState} from 'react';
import {Button, Icon} from '@ezcater/tapas';
import {faChevronDown} from '@fortawesome/pro-regular-svg-icons';
import {AnimatePresence} from 'motion/react';
import * as motion from 'motion/react-m';
import {twMerge} from 'tailwind-merge';

import useTracking from '@/hooks/useTracking';

type AccordionProps = React.PropsWithChildren<{
  defaultOpen?: boolean;
  header: React.ReactNode;
  hideIcon?: boolean;
  onToggle?: (isOpen: boolean) => void;
  slotProps?: {
    content?: React.ComponentPropsWithoutRef<typeof motion.div>;
    headerButton?: React.ComponentPropsWithoutRef<typeof Button>;
    icon?: React.ComponentPropsWithoutRef<typeof Icon>;
    root?: React.ComponentPropsWithoutRef<'div'>;
    subheader?: React.ComponentPropsWithoutRef<'div'>;
  };
  subheader?: React.ReactNode;
  trackingName?: string;
  triggerElement?: React.ReactNode;
}>;

type Ref = HTMLDivElement;

const Accordion = forwardRef<Ref, AccordionProps>(
  (
    {
      children,
      defaultOpen = false,
      header,
      hideIcon = false,
      onToggle,
      slotProps = {},
      subheader,
      trackingName,
      triggerElement,
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(defaultOpen);
    const {trackClick} = useTracking();

    useEffect(() => onToggle?.(isOpen), [isOpen, onToggle]);

    const handleToggle = () => {
      const newIsOpen = !isOpen;
      setIsOpen(!isOpen);

      if (trackingName) {
        trackClick(`${trackingName}-section-${newIsOpen ? 'open' : 'close'}`);
      }
    };

    return (
      <div ref={ref} {...slotProps?.root} className={twMerge('w-full', slotProps?.root?.className)}>
        <Button
          aria-controls="accordion-content"
          aria-expanded={isOpen}
          onClick={handleToggle}
          variant="secondary"
          {...slotProps?.headerButton}
          className={twMerge(
            'mb-1 flex w-full items-center justify-between rounded-none border-b border-peppercorn-200 p-0 no-underline hover:bg-transparent active:bg-transparent',
            slotProps?.headerButton?.className,
          )}
        >
          {header}

          {!hideIcon &&
            (triggerElement || (
              <motion.div
                animate={{rotate: isOpen ? 180 : 0}}
                initial={false}
                transition={{duration: 0.2}}
              >
                <Icon icon={faChevronDown} {...slotProps?.icon} />
              </motion.div>
            ))}
        </Button>

        {subheader && (
          <div
            {...slotProps?.subheader}
            className={twMerge('w-full border-none', slotProps?.subheader?.className)}
          >
            {subheader}
          </div>
        )}

        <AnimatePresence initial={false}>
          {isOpen && (
            <motion.div
              animate={{height: isOpen ? 'auto' : 0, opacity: isOpen ? 1 : 0, overflow: 'hidden'}}
              exit={{height: 0, opacity: 0, overflow: 'unset'}}
              id="accordion-content"
              initial={{height: 0, opacity: 0, overflow: 'unset'}}
              role="region"
              transition={{duration: 0.2}}
              {...slotProps?.content}
              className={isOpen && twMerge('mt-2 overflow-hidden', slotProps?.content?.className)}
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  },
);

export default Accordion;
