import queryString from 'query-string';

import {SEARCH_NEW_PATH, SEARCH_ORDER_ID_PATH} from '@/paths';
import compilePath from '@/utils/compilePath';
import {getLastSearchPage} from '@/utils/pagination';
import useDerivedMenuData from './useDerivedMenuData';
import useFulfillmentDetail from './useFulfillmentDetail';
import useLocalStorageFilters from './useLocalStorageFilters';

const useReturnToSearchLink = () => {
  const {caterer} = useDerivedMenuData();
  const fulfillmentDetail = useFulfillmentDetail();
  const filters = useLocalStorageFilters(fulfillmentDetail?.address?.fullAddress);
  const searchPage = parseInt(getLastSearchPage(), 10);

  if (fulfillmentDetail) {
    const filtersQueryParams = filters ? queryString.parse(filters) : {};
    return (queryParams: Record<string, any> = {}) =>
      compilePath(
        SEARCH_ORDER_ID_PATH,
        {orderId: fulfillmentDetail.id},
        {...filtersQueryParams, ...queryParams, ...(searchPage > 1 && {page: searchPage})},
      );
  }

  return (queryParams: Record<string, any> = {}) =>
    compilePath(
      SEARCH_NEW_PATH,
      {},
      {
        'address[state]': caterer?.address?.state?.toLowerCase() ?? '',
        'address[city]': caterer?.address?.city?.toLowerCase()?.replace(/\s+/g, '-') ?? '',
        ...queryParams,
        ...(searchPage > 1 && {page: searchPage}),
      },
    );
};

export default useReturnToSearchLink;
