import {useCallback} from 'react';
import useTranslation from 'next-translate/useTranslation';

type Format = 'USD' | 'USD_WHOLE_DOLLAR';
type CurrencyFormatter = (value: string | number, format: Format) => string;

const longCode = (lng: string): string => {
  switch (lng) {
    case 'en':
      return 'en-US';

    default:
      return lng;
  }
};

const useCurrencyFormatter = (): CurrencyFormatter => {
  const {lang} = useTranslation();
  const langLongCode = longCode(lang);
  const formatter: CurrencyFormatter = useCallback(
    (value, format) => {
      const floatValue = typeof value === 'string' ? parseFloat(value) : value;

      switch (format) {
        case 'USD':
          return (floatValue / 100.0).toLocaleString(langLongCode, {
            style: 'currency',
            currency: 'USD',
          });

        case 'USD_WHOLE_DOLLAR':
          return (floatValue / 100.0).toLocaleString(langLongCode, {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });

        default:
          return floatValue.toString();
      }
    },
    [langLongCode],
  );

  return formatter;
};

export default useCurrencyFormatter;
