// @ts-strict-ignore
import {createContext} from 'react';

import {Currency, FulfillmentDetailStrategy} from '@/graphql/types';
import {UTC_TIMEZONE} from '@/utils/dateFormat';
import {type DerivedContext} from './types';

const DerivedDataContext = createContext<DerivedContext>({
  caterer: null,
  eventTimestamp: null,
  menuItemCutoffTimes: {},
  catererTimeZoneIdentifier: UTC_TIMEZONE,
  orderMinimum: {
    subunits: 0,
    currency: Currency.Usd,
  },
  orderStrategy: FulfillmentDetailStrategy.Delivery,
  menuData: null,
  isMenuDataLoading: false,
  isGroupOrderOwner: false,
  isGroupOrderEater: false,
  currentUserAccessScopes: [],
  hasAddToCartPermission: false,
  disclaimers: [],
  contentSummary: null,
});

export default DerivedDataContext;
