import {useState} from 'react';
import {ApolloError} from '@apollo/client';
import {captureException, captureMessage} from '@sentry/nextjs';

import {
  CateringMenuConsumerCartAndFulfillmentDetailCreateMutation,
  CateringMenuConsumerCartCreateMutation,
  ConsumerCartAddItemInput,
  ConsumerCartAddItemsMutation,
  ConsumerCartExperience,
  ConsumerCartRecommendationsDocument,
  ConsumerCartRemoveItemsMutation,
  ConsumerCartUpdateInput,
  ConsumerCartUpdateItemInput,
  ConsumerCartUpdateItemsMutation,
  ConsumerCartUpdateMutation,
  FulfillmentDetailStrategy,
  useCateringMenuConsumerCartAndFulfillmentDetailCreateMutation,
  useCateringMenuConsumerCartCreateMutation,
  useConsumerCartAddItemsMutation,
  useConsumerCartRemoveItemsMutation,
  useConsumerCartUpdateItemsMutation,
  useConsumerCartUpdateMutation,
} from '@/graphql/types';
import useOrderingExperience from '@/hooks/useOrderingExperience';
import {type UserErrors} from '../MenuItemModal/types';
import useDerivedMenuData from './useDerivedMenuData';
import useFulfillmentDetail from './useFulfillmentDetail';
import useMenuPageData from './useMenuPageData';
import {usePromoCode} from './usePromoCode';

export type QueryResultsType =
  | CateringMenuConsumerCartAndFulfillmentDetailCreateMutation['consumerCartAndFulfillmentDetailCreate']
  | CateringMenuConsumerCartCreateMutation['consumerCartCreate']
  | ConsumerCartUpdateMutation['consumerCartUpdate']
  | ConsumerCartAddItemsMutation['consumerCartAddItems']
  | ConsumerCartUpdateItemsMutation['consumerCartUpdateItems']
  | ConsumerCartRemoveItemsMutation['consumerCartRemoveItems'];

type Props = {
  onAddItemsToCartSuccess?: VoidFunction;
  onCartRemoveItemsSuccess?: VoidFunction;
  onCreateConsumerCartAndAddItemsSuccess?: VoidFunction;
  onCreateCartAndFDSuccess?: VoidFunction;
  onNetworkError?: (e: ApolloError) => void;
  onUserError?: (e: UserErrors) => void;
  onUpdateCartSuccess?: VoidFunction;
  debug?: Record<string, any>;
};

const useConsumerCartHelpers = ({
  onAddItemsToCartSuccess,
  onCartRemoveItemsSuccess,
  onCreateCartAndFDSuccess,
  onCreateConsumerCartAndAddItemsSuccess,
  onNetworkError,
  onUserError,
  onUpdateCartSuccess,
  debug,
}: Props) => {
  const [userErrors, setUserErrors] = useState<string[] | null>(null);
  const [networkError, setNetworkErrors] = useState(false);
  const {cartId, fulfillmentDetailId, setCartId} = useMenuPageData();
  const {caterer} = useDerivedMenuData();
  const {isMarketplace} = useOrderingExperience();
  const fulfillmentDetail = useFulfillmentDetail();
  const {
    promoCookieMatchesBrand,
    getCartCreateInput,
    removePromoCookie,
    isPromoCodeError,
    handlePromoCodeError,
  } = usePromoCode(caterer?.brand?.id);

  const handleUserErrors = (err: UserErrors, action: string) => {
    const errorMessages = err.map(e => e.message);
    setUserErrors(errorMessages);
    onUserError?.(err);
    captureMessage(`Received user errors for action: ${action}`, {
      contexts: {
        menu: {
          ...debug,
          caterer_id: caterer?.urlId,
          user_errors: err.map(e => `${e.path.join('.')}: ${e.message}`).join('; '),
          cart_id: cartId,
          fulfillment_detail_id: fulfillmentDetailId,
        },
      },
    });
  };

  const handleNetworkErrors = (e: ApolloError) => {
    setNetworkErrors(true);
    onNetworkError?.(e);
    captureException(e, {
      contexts: {
        menu: {
          ...debug,
          caterer_id: caterer?.urlId,
          cart_id: cartId,
          fulfillment_detail_id: fulfillmentDetailId,
        },
      },
    });
  };

  const handleSuccess = (successFunction?: any, queryResult?: QueryResultsType) => {
    setNetworkErrors(false);
    setUserErrors(null);
    if (successFunction && queryResult) {
      return successFunction(queryResult);
    }

    successFunction?.();
  };

  /*
   * Create cart and FD
   */

  const [createCartAndFulfillmentDetails, {loading: isCreatingCartAndFd}] =
    useCateringMenuConsumerCartAndFulfillmentDetailCreateMutation({onError: handleNetworkErrors});

  const experience = isMarketplace
    ? ConsumerCartExperience.Marketplace
    : ConsumerCartExperience.EzOrdering;

  const createCartAndFD = ({
    input,
    reorderId,
  }: {
    input: ConsumerCartAddItemInput[];
    reorderId?: string;
  }) => {
    const executeCreateCartAndFD = async ({excludePromoCode = false} = {}) => {
      if (!caterer?.uuid || !caterer?.urlId) return;

      const consumerCartCreateInput = getCartCreateInput(experience, input, {
        excludePromoCode,
        reorderId,
      });

      const result = await createCartAndFulfillmentDetails({
        variables: {
          input: {
            consumerCartCreateInput,
            fulfillmentDetailCreateInput: {
              address: {
                city: caterer?.address?.city ?? '',
                latitude: caterer?.address?.latitude ?? null,
                longitude: caterer?.address?.longitude ?? null,
                state: caterer?.address?.state ?? '',
              },
              strategy: FulfillmentDetailStrategy.Delivery,
            },
          },
          catererId: caterer.uuid,
          catererUrlId: caterer.urlId,
          experience,
        },
      });

      const {consumerCartAndFulfillmentDetailCreate} = result.data || {};

      if (consumerCartAndFulfillmentDetailCreate?.userErrors?.length) {
        const shouldRetry = isPromoCodeError(consumerCartAndFulfillmentDetailCreate.userErrors);

        if (shouldRetry && !excludePromoCode) {
          handlePromoCodeError(consumerCartAndFulfillmentDetailCreate.userErrors);
          // Retry without promo code
          return executeCreateCartAndFD({excludePromoCode: true});
        } else {
          handleUserErrors(
            consumerCartAndFulfillmentDetailCreate.userErrors,
            'CateringMenuConsumerCartAndFulfillmentDetailCreateMutation',
          );
          return;
        }
      }

      const returnedCartId = consumerCartAndFulfillmentDetailCreate?.consumerCart?.id;
      if (returnedCartId) setCartId(returnedCartId);

      // Clear promo code cookie if it matches the brand, otherwise leave it.
      if (promoCookieMatchesBrand) {
        removePromoCookie();
      }

      handleSuccess(onCreateCartAndFDSuccess, consumerCartAndFulfillmentDetailCreate);
    };

    return executeCreateCartAndFD();
  };

  /*
   * Create cart and add items
   */

  const createConsumerCartAndAddItems = ({
    input,
    reorderId,
  }: {
    input: ConsumerCartAddItemInput[];
    reorderId?: string;
  }) => {
    const executeCreateCart = async ({excludePromoCode = false} = {}) => {
      if (!caterer?.uuid || !fulfillmentDetail?.id) return;

      const cartCreateInput = getCartCreateInput(experience, input, {excludePromoCode, reorderId});

      const result = await createConsumerCartAndAddItemsMutation({
        variables: {
          input: cartCreateInput,
          catererId: caterer.uuid,
          fulfillmentDetailId: fulfillmentDetail.id,
        },
      });

      const {consumerCartCreate} = result.data || {};

      if (consumerCartCreate?.userErrors?.length) {
        const shouldRetry = isPromoCodeError(consumerCartCreate.userErrors);

        if (shouldRetry && !excludePromoCode) {
          handlePromoCodeError(consumerCartCreate.userErrors);
          // Retry without promo code
          return executeCreateCart({excludePromoCode: true});
        } else {
          handleUserErrors(consumerCartCreate.userErrors, 'CateringMenuConsumerCartCreateMutation');
          return;
        }
      }

      const consumerCartId = consumerCartCreate?.consumerCart?.id;
      if (consumerCartId) setCartId(consumerCartId);

      // Clear promo code cookie if it matches the brand, otherwise leave it.
      if (promoCookieMatchesBrand) {
        removePromoCookie();
      }

      handleSuccess(onCreateConsumerCartAndAddItemsSuccess, consumerCartCreate);
    };

    return executeCreateCart();
  };

  const [createConsumerCartAndAddItemsMutation, {loading: isCreatingCartWithItems}] =
    useCateringMenuConsumerCartCreateMutation({onError: handleNetworkErrors});

  const createCartAndAddItems = ({
    input,
    reorderId,
  }: {
    input: ConsumerCartAddItemInput[];
    reorderId?: string;
  }) => {
    if (caterer?.uuid && fulfillmentDetail?.id) {
      return createConsumerCartAndAddItems({input, reorderId});
    }
  };

  /*
   * Update cart
   */

  const [updateConsumerCart, {loading: isUpdating}] = useConsumerCartUpdateMutation({
    onCompleted: ({consumerCartUpdate}) => {
      if (consumerCartUpdate?.userErrors?.length) {
        return handleUserErrors(consumerCartUpdate.userErrors, 'ConsumerCartUpdateMutation');
      }
      handleSuccess(onUpdateCartSuccess, consumerCartUpdate);
    },
    onError: handleNetworkErrors,
  });

  const updateCart = ({input}: {input: ConsumerCartUpdateInput}) => {
    if (cartId) {
      return updateConsumerCart({
        variables: {
          id: cartId,
          input,
        },
      });
    }
  };

  /*
   * Add to cart
   */

  const [addConsumerCartItems, {loading: isAddingItems}] = useConsumerCartAddItemsMutation({
    onCompleted: ({consumerCartAddItems}) => {
      if (consumerCartAddItems?.userErrors?.length) {
        return handleUserErrors(consumerCartAddItems.userErrors, 'ConsumerCartAddItemsMutation');
      }
      handleSuccess(onAddItemsToCartSuccess, consumerCartAddItems);
    },
    onError: handleNetworkErrors,
    refetchQueries: [ConsumerCartRecommendationsDocument],
  });

  const addItemsToCart = ({input}: {input: ConsumerCartAddItemInput[]}) => {
    if (cartId) {
      return addConsumerCartItems({
        variables: {
          id: cartId,
          input,
          experience,
        },
      });
    }
  };

  /*
   * Update cart items
   */

  const [updateConsumerCartItems, {loading: isUpdatingItems}] = useConsumerCartUpdateItemsMutation({
    onCompleted: ({consumerCartUpdateItems}) => {
      if (consumerCartUpdateItems?.userErrors?.length) {
        return handleUserErrors(
          consumerCartUpdateItems.userErrors,
          'ConsumerCartUpdateItemsMutation',
        );
      }
      handleSuccess(onUpdateCartSuccess, consumerCartUpdateItems);
    },
    onError: handleNetworkErrors,
  });

  const updateCartItems = ({input}: {input: ConsumerCartUpdateItemInput[]}) => {
    if (cartId) {
      return updateConsumerCartItems({
        variables: {
          id: cartId,
          input,
        },
      });
    }
  };

  /*
   * Remove from cart
   */

  const [consumerCartRemoveItems, {loading: isRemoving}] = useConsumerCartRemoveItemsMutation({
    onCompleted: ({consumerCartRemoveItems}) => {
      if (consumerCartRemoveItems?.userErrors?.length) {
        return handleUserErrors(
          consumerCartRemoveItems.userErrors,
          'ConsumerCartRemoveItemsMutation',
        );
      }
      handleSuccess(onCartRemoveItemsSuccess, consumerCartRemoveItems);
    },
    onError: handleNetworkErrors,
  });

  const removeCartItems = ({itemId}: {itemId: string}) => {
    if (cartId) {
      consumerCartRemoveItems({
        variables: {
          id: cartId,
          input: {
            id: itemId,
          },
        },
      });
    }
  };

  const isLoading =
    isCreatingCartWithItems ||
    isCreatingCartAndFd ||
    isAddingItems ||
    isUpdating ||
    isUpdatingItems ||
    isRemoving;

  return {
    addItemsToCart,
    createCartAndAddItems,
    createCartAndFD,
    isLoading,
    networkError,
    removeCartItems,
    updateCart,
    updateCartItems,
    userErrors,
  };
};

export default useConsumerCartHelpers;
